import { MetaTags, MetaTagsProps } from './MetaTags'

export const dummyMetaTags: MetaTagsProps = {
  _uid: '12345',
  title: 'Sample Page Title',
  plugin: 'seo_plugin',
  og_image: 'https://example.com/og_image.jpg',
  og_title: 'Sample OG Title',
  description: 'This is a sample description for the page.',
  twitter_image: 'https://example.com/twitter_image.jpg',
  twitter_title: 'Sample Twitter Title',
  og_description: 'This is a sample Open Graph description.',
  twitter_description: 'This is a sample Twitter description.',
}

export default MetaTags
