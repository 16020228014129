import useGoogleTracking from '@hooks/useGoogleTracking'
import useRedirectToStandard from '@hooks/useRedirectToStandard'
import useTagmanager from '@hooks/useTagmanager'
import useUserCentrics from '@hooks/useUserCentrics'
import {
  getStoryblokApi,
  StoryblokComponent,
  useStoryblokState,
} from '@storyblok/react'
import { GetStaticProps, InferGetStaticPropsType } from 'next'
import { useRouter } from 'next/router'
import Script from 'next/script'
import React, { useEffect } from 'react'

import Box from '@components/atoms/Box'
import MetaTags from '@components/molecules/MetaTags'
import { BackupMetaSunpoint } from '@components/molecules/MetaTags/MetaTags'
import SunpointTemplateDefault from '@components/templates/SunpointTemplateDefault'

import RealEnvironmentContext from '@helper/context/RealEnviromentContext'
import GoogleApiScriptLoaderWrapper from '@helper/GoogleApiScriptLoaderWrapper'

import { PageProps, transformToNested } from '.'

interface StoryblokComponent {
  component: string
  _uid: string
  [key: string]: any
}

export default function SunpointPage({
  story: initialStory,
  menuData,
}: InferGetStaticPropsType<typeof getStaticProps>) {
  const story = useStoryblokState(initialStory)
  useRedirectToStandard()
  useUserCentrics()
  useTagmanager()
  const { trackPageView } = useGoogleTracking()
  const router = useRouter()

  useEffect(() => {
    trackPageView(router.asPath, story?.name ?? '')
  }, [router.asPath, story, trackPageView])

  return (
    <SunpointTemplateDefault menuData={transformToNested(menuData)}>
      {story?.content.metatags !== undefined && (
        <MetaTags
          _uid={story?.content.metatags._uid}
          title={story?.content.metatags.title}
          plugin={story?.content.metatags.plugin}
          og_image={story?.content.metatags.og_image}
          og_title={story?.content.metatags.og_title}
          description={story?.content.metatags.description}
          twitter_image={story?.content.metatags.twitter_image}
          twitter_title={story?.content.metatags.twitter_title}
          og_description={story?.content.metatags.og_description}
          twitter_description={story?.content.metatags.twitter_description}
        />
      )}
      {story?.content.metatags === undefined && <BackupMetaSunpoint />}
      <Script
        data-settings-id={process.env.userCentricsId ?? ''}
        id="usercentrics-cmp"
        src="https://app.usercentrics.eu/browser-ui/latest/loader.js"
      ></Script>
      <RealEnvironmentContext.Provider value={{ isRealEnvironment: true }}>
        <GoogleApiScriptLoaderWrapper>
          {(!story || !story.content) && <Box>Loading...</Box>}
          {story && story.content && (
            <StoryblokComponent blok={story.content} />
          )}
        </GoogleApiScriptLoaderWrapper>
      </RealEnvironmentContext.Provider>
    </SunpointTemplateDefault>
  )
}

export const getStaticProps: GetStaticProps<PageProps> = async (
  context: any
) => {
  const storyblokApi = getStoryblokApi()
  const { slug } = context.params
  let story
  let links

  try {
    let { data: storyData } = await storyblokApi.get(
      `cdn/stories/sunpoint/${slug.join('/')}`,
      {
        version: 'draft',
      }
    )
    if (storyData && storyData.story) {
      story = storyData.story
    }
  } catch (error) {
    console.error('Fehler beim Abrufen der Story-Daten:', error)
  }

  try {
    let { data: linkData } = await storyblokApi.get('cdn/links', {
      version: 'draft',
      per_page: 5000,
      starts_with: 'sunpoint',
      is_startpage: true,
      with_tag: 'InHeaderNavigation',
    })
    if (linkData) {
      links = linkData.links
    }
  } catch (error) {
    console.error('Fehler beim Abrufen der Links:', error)
  }
  if (story && links) {
    return {
      props: {
        story: story,
        menuData: links,
      },
      revalidate: 20,
    }
  } else {
    return {
      props: {
        story: {
          alternates: [],
          content: { body: [] },
          created_at: '',
          full_slug: '',
          group_id: '',
          id: 0,
          name: '',
          parent_id: 0,
          position: 0,
          published: null,
          slug: '',
          sort_by_date: null,
          tag_list: [],
          uuid: '',
          is_startpage: false,
          meta_data: {},
          published_at: '',
          first_published_at: '',
          lang: '',
        },
        menuData: [],
      },
      revalidate: 20,
    }
  }
}

export const getStaticPaths: any = async () => {
  const storyblokApi = getStoryblokApi()
  let { data } = await storyblokApi.get('cdn/links/', {
    version: 'draft',
    per_page: 5000,
    starts_with: 'sunpoint',
  })

  const paths: any = []

  Object.values(data.links).forEach((link: any) => {
    const inititialSlug = link.slug
    if (!inititialSlug.startsWith('sunpoint')) {
      return
    }
    let slug = inititialSlug.substring('sunpoint/'.length)
    if (slug === 'home' || slug === '') {
      return
    }
    let splittedSlug = slug.split('/').filter((el: string) => el.length != 0)
    paths.push({ params: { slug: splittedSlug, locale: 'de' } })
  })
  return {
    paths: paths,
    fallback: 'blocking',
  }
}
